const queryResults = {
    query: "SELECT * FROM Customers;",
    results: [
        {
            CustomerID: 1,
            CustomerName: "Alfreds Futterkiste",
            ContactName: "Maria Anders",
            Address: "Obere Str. 57",
            City: "Berlin",
            PostalCode: 12209,
            Country: "Germany",
        },
        {
            CustomerID: 2,
            CustomerName: "Ana Trujillo Emparedados y helados",
            ContactName: "Ana Trujillo",
            Address: "Avda. de la Constitución 2222",
            City: "México D.F.",
            PostalCode: "05021",
            Country: "Mexico",
        },
        {
            CustomerID: 3,
            CustomerName: "Antonio Moreno Taquería",
            ContactName: "Antonio Moreno",
            Address: "Mataderos 2312",
            City: "México D.F.",
            PostalCode: "05023",
            Country: "Mexico",
        },
        {
            CustomerID: 4,
            CustomerName: "Around the Horn",
            ContactName: "Thomas Hardy",
            Address: "120 Hanover Sq.",
            City: "London",
            PostalCode: "WA1 1DP",
            Country: "UK",
        },
        {
            CustomerID: 5,
            CustomerName: "Berglunds snabbköp",
            ContactName: "Christina Berglund",
            Address: "Berguvsvägen 8",
            City: "Luleå",
            PostalCode: "S-958 22",
            Country: "Sweden",
        },
        {
            CustomerID: 6,
            CustomerName: "Blauer See Delikatessen",
            ContactName: "Hanna Moos",
            Address: "Forsterstr. 57",
            City: "Mannheim",
            PostalCode: 68306,
            Country: "Germany",
        },
        {
            CustomerID: 7,
            CustomerName: "Blondel père et fils",
            ContactName: "Frédérique Citeaux",
            Address: "24, place Kléber",
            City: "Strasbourg",
            PostalCode: 67000,
            Country: "France",
        },
        {
            CustomerID: 8,
            CustomerName: "Bólido Comidas preparadas",
            ContactName: "Martín Sommer",
            Address: "C/ Araquil, 67",
            City: "Madrid",
            PostalCode: 28023,
            Country: "Spain",
        },
        {
            CustomerID: 9,
            CustomerName: "Bon app'",
            ContactName: "Laurence Lebihans",
            Address: "12, rue des Bouchers",
            City: "Marseille",
            PostalCode: 13008,
            Country: "France",
        },
        {
            CustomerID: 10,
            CustomerName: "Bottom-Dollar Marketse",
            ContactName: "Elizabeth Lincoln",
            Address: "23 Tsawassen Blvd.",
            City: "Tsawassen",
            PostalCode: "T2F 8M4",
            Country: "Canada",
        },
    ],
};

export default queryResults;
